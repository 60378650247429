<script>
import Layout from "../layouts/auth";

export default {
  locales: {
    pt: {

    },
    es: {

    }
  },
  components: {
    Layout
  },
  data() {
    return {
    };
  }
};
</script>

<template>
  <Layout>
    <div id="auth" class="row m-0 flex-column-reverse flex-sm-row align-items-center">
      <div class="col">
        <div class="login-content">
          <div class="pt-5 text-center">
            <router-link class="align-middle" tag="a" to="/">
              <img class="w-100" style="max-width:180px;" src="@/assets/images/logo.png" />
            </router-link>
          </div>
          <div class="py-5 text-center">
            <h5 class="m-0">Em Manutenção</h5>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
